// src/index.js
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './i18n'; // Инициализация i18n
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { useSessionStore } from './sessionStore'; 

// Функция для получения traffID из пути URL
function getTraffIdFromPath() {
  const pathSegments = window.location.pathname.split('/').filter(Boolean);
  const traffID = pathSegments.length > 0 ? pathSegments[pathSegments.length - 1] : '';
  return traffID;
}

// Генерация нового sessionID
function generateSessionID() {
  return 'sess_' + Math.random().toString(36).substr(2, 9);
}

const Root = () => {
  const [initialized, setInitialized] = useState(false);

  // Получаем методы из sessionStore
  const updateSessionId = useSessionStore(state => state.updateSessionId);
  const updateSessionState = useSessionStore(state => state.updateSessionState);

  useEffect(() => {
    // Получаем SessionID из localStorage
    const savedSessionID = localStorage.getItem('SessionID');
    let sessionID = savedSessionID;

    if (!sessionID) {
      // Если SessionID нет в localStorage, генерируем новый
      sessionID = generateSessionID();
    }

    // Устанавливаем SessionID в sessionStore
    updateSessionId(sessionID);

    // Получаем TraffID из пути URL
    const traffID = getTraffIdFromPath();

    // Обновляем sessionState с TraffID
    updateSessionState({ traffID });

    // Инициализация завершена
    setInitialized(true);

  }, [updateSessionId, updateSessionState]);

  if (!initialized) {
    // Рендеринг состояния загрузки до завершения инициализации
    return <div>Loading...</div>;
  }

  return (
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  );
};

ReactDOM.createRoot(document.getElementById('root')).render(<Root />);
