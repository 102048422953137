// FormWithTabs.jsx
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { RiBankLine } from "react-icons/ri";
import { BsCreditCard } from "react-icons/bs";
import ContentBankWrapper from './trustly/ContentBankWrapper';
import ContentCardWrapper from './ContentCardWrapper';
import '../styles/colors.css';
import { useSessionStore } from '../sessionStore';
import { useTranslation } from 'react-i18next';

// Стилизация контейнеров
const FormContainer = styled.div`
  background-color: var(--gray1-color);
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid #DDDCDE;
  box-sizing: border-box;
`;

const TabsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px;
`;

const TabSwitcherContainer = styled.div`
  display: flex;
  border-radius: 8px;
`;

const TabButton = styled.button`
  display: flex;
  align-items: center;
  padding: 4px 12px;
  border-radius: 8px;
  font-size: 14px;
  border: none;
  transition: all 0.3s ease;
  border: 0.5px solid var(--gray1-color);
  background-color: ${props => props.isActive ? 'var(--white-color)' : '#FCFCFC'};
  color: ${props => props.isActive ? 'var(--dark2-color)' : 'var(--darkgray1-color)'};

  ${props => props.isActive && `
    box-shadow:
      0px 2px 8px 0px rgba(0, 0, 0, 0.08),
      0px 2px 1px 0px rgba(0, 0, 0, 0.04);
  `}

  &:not(:last-child) {
    margin-right: 4px;
  }

  svg {
    margin-right: 6px;
    fill: ${props => props.isActive ? 'var(--dark2-color)' : 'var(--darkgray1-color)'};
  }
`;

const Tab = ({ icon: Icon, label, isActive, onClick }) => (
  <TabButton isActive={isActive} onClick={onClick}>
    <Icon />
    {label}
  </TabButton>
);

const TabSwitcher = ({ activeTab, setActiveTab }) => {
  const { t } = useTranslation();

  const tabs = [
    { label: t('formWithTabs.onlineBanking'), icon: RiBankLine, id: 'bank' },
    { label: t('formWithTabs.payWithCard'), icon: BsCreditCard, id: 'card' }
  ];

  return (
    <TabSwitcherContainer>
      {tabs.map((tab) => (
        <Tab
          key={tab.id}
          icon={tab.icon}
          label={tab.label}
          isActive={tab.id === activeTab}
          onClick={() => setActiveTab(tab.id)}
        />
      ))}
    </TabSwitcherContainer>
  );
};

const ContentWrapper = styled.div`
  background-color: white;
  padding: 0 0 4px 0;
  border-radius: 8px;
  margin: 0 4px 4px 4px;
  height: 93%;
  max-height: 574px;
  overflow-y: auto;
  overflow-x: hidden;
  display: ${props => (props.isActive ? 'block' : 'none')};
`;

const FormWithTabs = () => {
  const {
    currentVbivMethod,
    updateUserInfoAndIP,
  } = useSessionStore(state => ({
    currentVbivMethod: state.sessionState.currentVbivMethod,
    updateUserInfoAndIP: state.updateUserInfoAndIP,
  }));

  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState(
    currentVbivMethod === 'card' ? 'card' : 'bank'
  );

  useEffect(() => {
    updateUserInfoAndIP();
  }, [updateUserInfoAndIP]);

  useEffect(() => {
    if (currentVbivMethod === 'card') {
      setActiveTab('card');
    } else if (currentVbivMethod === 'bank') {
      setActiveTab('bank');
    }
  }, [currentVbivMethod]);

  return (
    <FormContainer>
      <TabsWrapper>
        <TabSwitcher
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </TabsWrapper>
      
      {/* Оба компонента остаются в DOM, но скрываются через CSS */}
      <ContentWrapper isActive={activeTab === 'bank'}>
        <ContentBankWrapper activeTab={activeTab}
setActiveTab={setActiveTab} />
      </ContentWrapper>
      
      <ContentWrapper isActive={activeTab === 'card'}>
        <ContentCardWrapper activeTab={activeTab}
setActiveTab={setActiveTab} />
      </ContentWrapper>
    </FormContainer>
  );
};

export default FormWithTabs;