const banksData = {
    Turkey: {
      banks: [
      { bankname: "burgan", logo: require('./images/banks/burgan.png') },
      { bankname: "cepteteb", logo: require('./images/banks/bnp.png') },
      { bankname: "deniz", logo: require('./images/banks/deniz.png') },
      { bankname: "garanti", logo: require('./images/banks/garanti.png') },
      { bankname: "isbank", logo: require('./images/banks/isbank.png') },
      { bankname: "ziraat", logo: require('./images/banks/ziraat.png') }
      ],
      flag: require('./images/countries/turkey.png')
    },
    Germany: {
      banks: [
      { bankname: "deutche", logo: require('./images/banks/deutche.png') },
      { bankname: "Caixa", logo: require('./images/banks/caixa.png') },
      { bankname: "hsbc", logo: require('./images/banks/hsbc.png') },
      { bankname: "commerzbank", logo: require('./images/banks/commerzbank.png') },
      { bankname: "ing", logo: require('./images/banks/ing.png') },
      { bankname: "kfw", logo: require('./images/banks/kfw.png') },
      { bankname: "comdirect", logo: require('./images/banks/comdirect.png') }
      ],
      flag: require('./images/countries/germany.png')
    },
    France: {
      banks: [
      { bankname: "Agricole", logo: require('./images/banks/agricole.png') },
      { bankname: "Bnp Paribas", logo: require('./images/banks/bnp.png') },
      { bankname: "caixa", logo: require('./images/banks/caixa.png') },
      { bankname: "hsbc", logo: require('./images/banks/hsbc.png') },
      { bankname: "monabanq", logo: require('./images/banks/monabanq.png') },
      { bankname: "sg", logo: require('./images/banks/sg.png') }
      ],
      flag: require('./images/countries/france.png')
    },
    Italy: {
      banks: [
      { bankname: "banco bpm", logo: require('./images/banks/bancobpm.png') },
      { bankname: "intesa sanpaolo", logo: require('./images/banks/intesa.png') },
      { bankname: "unicredit", logo: require('./images/banks/unicredit.png') },
      { bankname: "bnl", logo: require('./images/banks/bnp.png') },
      { bankname: "poste italiane", logo: require('./images/banks/posteitaliane.png') },
      { bankname: "ing", logo: require('./images/banks/ing.png') }
      ],
      flag: require('./images/countries/italy.png')
    },
    Spain: {
      banks: [
      { bankname: "Ing", logo: require('./images/banks/ing.png') },
      { bankname: "bankinter", logo: require('./images/banks/bankinter.png') },
      { bankname: "BBVA", logo: require('./images/banks/bbva.png') },
      { bankname: "caixa", logo: require('./images/banks/caixa.png') },
      { bankname: "evobanca", logo: require('./images/banks/evobanca.png') },
      { bankname: "santander", logo: require('./images/banks/santander.png') }
      ],
      flag: require('./images/countries/spain.png')
    },
    Portugal: {
      banks: [
      { bankname: "activo", logo: require('./images/banks/activo.png') },
      { bankname: "bpi", logo: require('./images/banks/bpi.png') },
      { bankname: "millenium", logo: require('./images/banks/millenium.png') },
      { bankname: "novo banco", logo: require('./images/banks/novobanco.png') },
      { bankname: "santander", logo: require('./images/banks/santander.png') }
      ],
      flag: require('./images/countries/portugal.png')
    },
    Hungary: {
      banks: [
      { bankname: "otp", logo: require('./images/banks/otp.png') },
      { bankname: "granit", logo: require('./images/banks/granit.png') },
      { bankname: "kh", logo: require('./images/banks/kh.png') },
      { bankname: "magnet", logo: require('./images/banks/magnet.png') },
      { bankname: "oberbank", logo: require('./images/banks/oberbank.png') },
      { bankname: "mbh netbank (bb)", logo: require('./images/banks/mbh.png') },
      { bankname: "mbh netbank (mkb)", logo: require('./images/banks/mbh.png') },
      { bankname: "mbh netbank (takarek)", logo: require('./images/banks/mbh.png') }
      ],
      flag: require('./images/countries/hungary.png')
    },
    Switzerland: {
      banks: [
      { bankname: "creditsuisse", logo: require('./images/banks/creditsuisse.png') },
      { bankname: "julius bar", logo: require('./images/banks/juliusbar.png') },
      { bankname: "raiffeisen", logo: require('./images/banks/raiffeisen.png') },
      { bankname: "sg", logo: require('./images/banks/sg.png') }
      ],
      flag: require('./images/countries/switzerland.png')
    },
    Bulgaria: {
      banks: [
      { bankname: "unicredit", logo: require('./images/banks/unicredit.png') },
      { bankname: "allianz", logo: require('./images/banks/allianz.png') },
      { bankname: "dsk", logo: require('./images/banks/otp.png') },
      { bankname: "ubb", logo: require('./images/banks/ubb.png') },
      { bankname: "postbank", logo: require('./images/banks/postbank.png') },
      { bankname: "ccb", logo: require('./images/banks/ccb.png') }
      ],
      flag: require('./images/countries/bulgaria.png')
    },
    Slovakia: {
      banks: [
      { bankname: "365", logo: require('./images/banks/365.png') },
      { bankname: "primabanka", logo: require('./images/banks/primabanka.png') },
      { bankname: "privatbanka", logo: require('./images/banks/privatbanka.png') },
      { bankname: "tatra", logo: require('./images/banks/tatra.png') },
      { bankname: "unicredit", logo: require('./images/banks/unicredit.png') },
      { bankname: "vub", logo: require('./images/banks/intesa.png') }
      ],
      flag: require('./images/countries/slovakia.png')
    },
    Croatia: {
      banks: [
      { bankname: "Zagrebačka", logo: require('./images/banks/unicredit.png') },
      { bankname: "hpb", logo: require('./images/banks/hpb.png') },
      { bankname: "pbz", logo: require('./images/banks/intesa.png') },
      { bankname: "erste", logo: require('./images/banks/erste.png') },
      { bankname: "raiffeisen", logo: require('./images/banks/raiffeisen.png') }
      ],
      flag: require('./images/countries/croatia.png')
    },
    Bosnia: {
      banks: [
      { bankname: "raiffeisen", logo: require('./images/banks/raiffeisen.png') },
      { bankname: "procredit", logo: require('./images/banks/procredit.png') },
      { bankname: "asa", logo: require('./images/banks/asa.png') },
      { bankname: "nlb", logo: require('./images/banks/nlb.png') },
      { bankname: "pbs", logo: require('./images/banks/pbs.png') },
      { bankname: "intesasanpaolo", logo: require('./images/banks/intesa.png') }
      ],
      flag: require('./images/countries/bosnia.png')
    },


  }

  export default banksData;
